import { defineStore } from "pinia";
import { nextTick } from "vue";
import { useCookies } from "vue3-cookies";
import Emitter from "Plugins/mitt";
import API from "Api/API";
import AppConfig from "Constants/AppConfig";

const getAuthCookie = (name) => {
  const { cookies } = useCookies();
  const tokenData = cookies.get(name);
  return tokenData ? JSON.parse(atob(tokenData)) : null;
};
const setAuthCookie = (name, tokenData) => {
  try {
    const { cookies } = useCookies();
    const expire = name == "_auth" ? 60 * 60 : "30D";
    cookies.set(name, btoa(JSON.stringify(tokenData)), expire, null);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const useAuthStore = defineStore("auth", {
  state: () => ({
    tokenData:
      AppConfig.config.tokenMode === "COOKIE" ? getAuthCookie("_auth") : null,
    refreshTokenData:
      AppConfig.config.tokenMode === "COOKIE" ? getAuthCookie("_auth_r") : null,
    lastToken: null,
  }),
  getters: {
    token() {
      return this.tokenData?.token;
    },
    refreshToken() {
      return this.refreshTokenData?.token;
    },
    loggedIn() {
      return (
        (this.token && this.tokenData?.expires > Date.now()) ||
        (this.refreshToken && this.refreshTokenData?.expires > Date.now())
      );
    },
  },
  actions: {
    clearData() {
      const { cookies } = useCookies();
      const profileStore = useProfileStore();
      const adminStore = useAdminStore();
      const sessionStore = useSessionStore();

      this.tokenData = null;
      this.refreshTokenData = null;
      this.lastToken = null;

      profileStore.user = null;
      profileStore.identities = [];
      profileStore.adminScreen = false;

      sessionStore.contacts = [];
      sessionStore.accounts = [];
      sessionStore.selectedAccounts = [];
      sessionStore.numbers = [];
      sessionStore.tags = {};

      adminStore.identity = null;

      cookies.remove("_wj_ob");
      cookies.remove("_auth");
      cookies.remove("_auth_r");
    },
    async logout(redirect = null, clear = true) {
      const adminStore = useAdminStore();
      const { identity } = storeToRefs(adminStore);

      Emitter.emit("freeRoute");
      await nextTick();

      try {
        await API.Auth.logout();
      } catch (e) {}

      if (clear) {
        this.clearData();
      }

      const path = redirect
        ? { name: "auth-login", query: { redirect: btoa(redirect) } }
        : { name: "auth-login" };
      this.$router.push(path);
      setTimeout(() => {
        identity.value = null;
      }, 2000);
    },
    async tokenRefresh() {
      if (
        (!this.tokenData ||
          (this.tokenData && this.tokenData.expires < Date.now())) &&
        this.refreshTokenData &&
        this.refreshTokenData.expires > Date.now()
      ) {
        const payload = {
          refresh: this.refreshTokenData.jwt,
          last: this.lastToken,
        };
        try {
          const response = await API.Auth.refresh(payload);
          this.setAuth(response);
          await nextTick();
          return true;
        } catch (e) {
          throw e;
        }
      }
    },
    setRefresh(tokenData) {
      const expireMS = 30 * 24 * 60 * 60 * 1000; //30 days
      this.refreshTokenData = Object.assign(tokenData, {
        expires: new Date(new Date().getTime() + expireMS).getTime(),
      });
      if (AppConfig.config.tokenMode == "COOKIE") {
        setAuthCookie("_auth_r", this.refreshTokenData);
      }
    },
    setAuth(tokenData) {
      const expireMS = 60 * 60 * 1000; //60 min
      this.tokenData = Object.assign(tokenData, {
        expires: new Date(new Date().getTime() + expireMS).getTime(),
      });
      this.lastToken = tokenData.token;
      if (AppConfig.config.tokenMode == "COOKIE") {
        setAuthCookie("_auth", this.tokenData);
      }
    },
    async getToken() {
      if (this.tokenData && this.tokenData.expires > Date.now()) {
        return this.tokenData.token;
      }
      if (this.refreshTokenData && this.refreshTokenData.expires > Date.now()) {
        try {
          await this.tokenRefresh();
          return this.tokenData.token;
        } catch (e) {
          throw e;
        }
      }
      return false;
    },
  },
  persist: AppConfig.config.tokenMode === "STORAGE",
});
